@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.sliderText {
  font-family: "Playfair Display";
}

.sliderImg {
  width: 50% !important;
}

@media (max-width: 1300px) {
  .sliderImg {
    width: 60% !important;
  }
}
@media (max-width: 1100px) {
  .sliderImg {
    width: 55% !important;
  }
}

@media (max-width: 1024px) {
  .sliderImg {
    width: 100% !important;
  }
}

.mySwiper{
  padding: 0 !important;
}

.swiper-slide{
  height: 450px !important;
}