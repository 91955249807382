.collapse-content {
  padding-bottom: 0 !important;
}

.drawer-side {
  font-size: 14px;
}

/* width */
#sidebar::-webkit-scrollbar {
  width: 7px;
  height: 4px;
}

/* Track */
#sidebar::-webkit-scrollbar-track {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/* Handle */
#sidebar::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/* ul.menu{
  overflow-y: scroll;
}

ul.menu::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

ul.menu::-webkit-scrollbar-thumb{
  background: rgb(0 53 223 / 79%);
  border-radius: 3px;
} */

.collapse-title,
.collapse > input[type="checkbox"] {
  min-height: 0 !important;
}

a.sidemenu {
  height: 43px !important;
}

.sidemenu-parent {
  height: 43px !important;
  margin: 0;
  padding: 0.15rem 0.9rem !important;
}

.sidemenu.active .icon {
  color: #fff !important;
}

.sidemenu:hover .icon {
  color: #fff !important;
  transition-duration: 200ms;
}

.sidemenu:hover .queryIcon {
  filter: brightness(0) invert(1);
}

.sidemenu.active .queryIcon {
  filter: brightness(0) invert(1);
}

.collapse-arrow .collapse-title:after {
  top: 0.9rem;
}

.parent-dropdown:hover .collapse-title {
  background-color: #f4900c !important;
  color: #fff !important;
}

.parent-dropdown:hover .icon {
  color: #fff !important;
  transition-duration: 200ms;
}
:dir("rtl") .drawer-toggle ~ .drawer-side {
  /* overflow-y: auto; */
  overflow-y: hidden;
  margin-right: -500px;
}

:dir(rtl) .drawer-toggle:checked ~ .drawer-side {
  /* overflow-y: auto; */
  overflow-y: hidden;
  margin-right: 0px;
}

@media (max-width: 992px) {
  .drawer-toggle ~ .drawer-side:dir(rtl) > .drawer-overlay + * {
    --tw-translate-x: 100%;
  }

  .drawer-toggle:checked ~ .drawer-side:dir(rtl) > .drawer-overlay + * {
    --tw-translate-x: 0;
  }

  /* .vendorSidebar {
    --tw-translate-x: 100% !important;
  } */
}

ul.menu::-webkit-scrollbar-track {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

ul.menu::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.drawer-toggle:checked ~ .drawer-side > .drawer-overlay ::-webkit-scrollbar {
  width: 7px;
  height: 4px;
}

.drawer-toggle:checked ~ .drawer-side > .drawer-overlay + * {
  overflow-y: scroll;
}

.parent-dropdown .collapse-content ul li a:hover .sidebar-circle {
  background-color: #707070;
}

.sidebar-circle {
  height: 6px;
  width: 6px;
  border: 1px solid #707070;
  border-radius: 50%;
  top: calc(50% - 3px);
  left: 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
