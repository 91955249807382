.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-prev{
    color: black;
    background-color: #dddddd;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .swiper-button-prev::after{
    font-size: 20px;
  }
  .swiper-button-next{
    color: black;
    background-color: #dddddd;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .swiper-button-next::after{
    font-size: 20px;
  }