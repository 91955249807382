@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #21272b;
  --txt-white: #fff;
  --main-color: #349eff;
  --second-color: #62b4ff;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #349eff;
  --second-color-blue: #62b4ff;

  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;

  --main-color-green: #019707;
  --second-color-green: #4caf50;

  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;

  --sidebar-width: 300px;
  --border-radius: 15px;
  --topnav-height: 110px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  font-family: "Roboto", "Open Sans", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  /* font-family: "Roboto"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  display: none;
}

#dashboard {
  font-family: "Roboto", Helvetica, sans-serif !important;
}

#dashboard p {
  font-family: "Roboto", Helvetica, sans-serif !important;
}

.slick-prev {
  color: black;
}

.active {
  /* background-color: #F4900C !important; */
  background-color: #f4900c !important;
  padding: 10px;
  border-radius: 10px;
  color: white;
  text-decoration: none !important;
}

.Container {
  padding: 0 calc((100vw - 1200px) / 2);
}

/* scroll button css */
.scrollBtn {
  transition: all 0.3s ease;
  transform: translateY(10px);
  opacity: 0;
  pointer-events: none;
  z-index: 9;
}

.scrollBtn_active {
  transform: translateY(0px);
  opacity: 1;
  pointer-events: all;
}

.roboto {
  font-family: "Roboto", Helvetica, sans-serif !important;
}

.font-domain {
  font-family: "Domine", serif;
}

.text-13 {
  font-size: 13px !important;
}

p,
.poppin {
  font-family: "Poppins", sans-serif;
}

.paragaraph-font {
  font-family: "Poppins", sans-serif;
}

.font-opensans {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

.text-xs {
  font-size: 13px !important;
}

.heading {
  font-family: "Trirong", sans-serif;
  line-height: 3rem;
}

.font-trirong {
  font-family: "Trirong", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}

/* Firefox */
input[type="number"] {
  /* -moz-appearance: textfield; */
}

/* global sidebar settings */

/* width */
/* ::-webkit-scrollbar {
  width: 14px;
  height: 4px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #449D9E;
  
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #2e6a6b;
}
 */

/* imported from admin tutorial */

a {
  text-decoration: none;
  color: unset;
}

/* a:hover {
  color: var(--main-color);
} */

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--txt-color);
}

ul {
  list-style-type: none;
}

input {
  border: 2px solid transparent;
  outline: 0;
}

input:focus {
  border: 1px solid #ff8264;
}

.page-header {
  margin-bottom: 40px;
  text-transform: capitalize;
}

.card {
  padding: 30px;
  margin-bottom: 30px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
}

.card>div~div {
  margin-top: 30px;
}

.card__footer {
  text-align: center;
  text-transform: capitalize;
}

.light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}

.blue-color {
  background-color: var(--main-color-blue);
  color: #fff;
}

.red-color {
  background-color: var(--main-color-red);
  color: #fff;
}

.cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}

.green-color {
  background-color: var(--main-color-green);
  color: #fff;
}

.orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}

.dashboard-box {
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  box-shadow: 2px 2px 5px 1px rgb(188 188 188 / 39%);
}

.reviewBox {
  box-shadow: 4px 3px 10px 2px #a4a3a3;
}

.react-tel-input input.form-control {
  width: 100% !important;
  margin-top: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

li a.active {
  color: #fff !important;
  text-decoration: none;
}

.home-link li a:hover {
  text-decoration: none !important;
  color: #f4900c !important;
}

.home-link li a.active {
  text-decoration: none !important;
  color: #f4900c !important;
}

li a:hover {
  text-decoration: none !important;
  color: white !important;
}

a:hover {
  text-decoration: none !important;
  color: #f4900c !important;
}

.ai:hover {
  text-decoration: none !important;
  color: #f4900c !important;
}

.in-pri:hover {
  color: #f4900c !important;
}

.in-pri2:hover {
  color: white !important;
}

svg.star-svg {
  display: inline;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 40px;
  color: rgba(58, 58, 58, 0.867);
  border: solid 0.5px rgb(192, 192, 192);
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  padding-left: 1rem;
}

.input-group :first-child {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0;
}

.input-group :last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0.25rem !important;
}

.select__control {
  height: 40px !important;
}

.dropdown-content li a {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 12px;
}

.dropdown-content li:hover {
  background-color: #f4900c;
  color: white;
}

.select-search-container {
  --select-search-font: "Inter var", sans-serif;
  width: 100% !important;
}

.activeTab {
  background-color: #f4900c;
  color: white;
}

.rdt_TableHeadRow {
  background: linear-gradient(0deg, #80808099, #f3f3f3f5);
  color: black;
  font-weight: 500;
}

/* modifying the styling of phone input box */

.react-tel-input .selected-flag .arrow {
  right: 20px;
}

.react-tel-input .selected-flag {
  padding: 8px !important;
}

/*  */
.dApqnJ {
  position: static !important;
  width: auto !important;
  display: contents !important;
}

@media (max-width: 1270px) {
  .iSAVrt {
    width: auto !important;
  }
}

@media (max-width: 770px) {
  .iSAVrt {
    width: 100% !important;
  }
}

@media (max-width: 470px) {
  .iSAVrt {
    width: auto !important;
  }
}

.guideLink {
  color: var(--txt-color) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.guideLink:hover {
  color: #f4900c !important;
  transition-timing-function: ease-in-out !important;
  transition-duration: 150ms !important;
  background-color: #f7f7f7 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.guideLink.active {
  color: #f4900c !important;
  background-color: #f7f7f7 !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

@media (max-width: 576px) {
  .guideLink.active {
    background-color: #fff !important;
  }
}

.navlink.active:hover,
.navlink:hover {
  color: rgb(17 24 39) !important;
}

.navlink.active {
  padding-left: 12px !important;
  padding-right: 12px !important;
  background: #fff !important;
  color: rgb(17 24 39) !important;
  text-decoration: underline !important;
}

/* storeThankyou */

.thankBtn:hover {
  color: white !important;
}

/* store contact us */
.storeContactText {
  font-family: "Domine";
}

.custom-search .ant-btn-primary {
  background-color: #f4900c !important;
  /* Change button color */
  border-color: #f4900c !important;
}

.custom-search .ant-btn-primary:hover {
  background-color: #f4900c !important;
  border-color: #f4900c !important;
}