.layout {
    color: var(--txt-color);
}

.layout__content {
    padding-left: var(--sidebar-width);
    background-color: var(--second-bg);
    min-height: 100vh;
}

.layout__content-main {
    padding: 30px;
}

.collapse-title, .collapse > input[type="checkbox"] {
   
    padding: .75rem 1rem !important;
}

.report-icons div{
    cursor:pointer;
}

.report-icons div:hover{
    background-color:white;
    transition: all 0.3s ease;

}
.report-icons div:hover svg{
    color: #FF8264;
}