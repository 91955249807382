.contactWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

/* Featured products styles start */
@media (min-width: 992px) {
  .single_product:hover .price {
    transform: translateY(-15px);
  }

  .single_product:hover .heart {
    transform: translateY(0px);
    opacity: 1;
    pointer-events: all;
  }

  .heart {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    transition: all 0.3s ease;
  }

  .price {
    transition: all 0.3s ease;
    transform: translateY(15px);
  }
}

/* Featured products styles end */

/* shop section start */
.shop_container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  /* gap: 20px; */
}

@media (max-width: 768px) {
  .shop_container {
    grid-template-columns: 1fr;
  }
}

.shopOption {
  border-radius: 20px !important;
}

/* shop section end */

@media (min-width: 768px) {
  .contactWrapper {
    grid-template-columns: 1fr 2fr;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

#reviewDiv {
  height: 240px !important;
}

#reviewImage {
  height: 220px !important;
}

.shopFooterLink {
  color: rgb(97, 96, 96);
  font-size: 15px;
}

.shopFooterLink:hover , .footIcon:hover{
  color: rgb(97, 96, 96) !important;
}

/* orders table */

.sc-dkrFOg {
  width: 100% !important;
}

@media (max-width: 600px) {
  .sc-fLcnxK {
    display: table !important;
  }
}
